import Image from "next/image"
import loadNamespaces from 'next-translate/loadNamespaces'
import { GetStaticPropsContext } from "next"
import useTranslation from "next-translate/useTranslation"

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      ...(await loadNamespaces({ locale })),
    }
  }
}

const NotFoundPage: React.FC = () => {
  const { t: tc } = useTranslation('common')
  return (
    <div
      style={{ minHeight: '60vh' }}
      className="container d-flex justify-content-center align-items-center"
    >
      <div className="p-5 d-flex flex-column">
        <div className="mb-4 d-flex justify-content-center">
          <Image
            src="/illustrations/not-found.svg"
            width={250}
            height={150}
            alt="page not found"
          />
        </div>
        <h1 className="text-center fs-5 lead first-letter-uppercase">
          {tc('sorry, we cannot find the page you request')}
        </h1>
      </div>
    </div>
  )
}

export default NotFoundPage
